import React from 'react'
import Link from 'gatsby-link'
import Layout from '../components/Layout'

// icons
import TrilogyIcon from '../images/about-icons/TrilogyEd.svg'
import ReactIcon from '../images/about-icons/react.png'
import FlutterIcon from '../images/about-icons/flutter-logo.svg'
import ReactNativeIcon from '../images/about-icons/react-native.png'
import WPIcon from '../images/about-icons/wordpress.png'
import NodeIcon from '../images/about-icons/node.png'
import PolymerIcon from '../images/about-icons/polymer.png'
import PhaserIcon from '../images/about-icons/phaser_logo.png'
import JavascriptIcon from '../images/about-icons/javascript.png'
import Jekylllogo from '../images/about-icons/jekyll.png'
import PHPlogo from '../images/about-icons/php.png'
import Unitylogo from '../images/about-icons/unity-logo.png'
import AdobeIllustratorlogo from '../images/about-icons/adobe-illustrator-cc.svg'
import AdobeAfterEffectslogo from '../images/about-icons/after-effects-cc.svg'
import AdobePhotoshoplogo from '../images/about-icons/photoshop-cc.svg'
import AdobeInDesignlogo from '../images/about-icons/indesign-cc.svg'
import AdobePremierePrologo from '../images/about-icons/premiere-cc.svg'
import AffinityDesignerlogo from '../images/about-icons/affinity-designer.png'
import AffinityPhotologo from '../images/about-icons/affinity-photo.png'
import RaspberryPilogo from '../images/about-icons/raspberry-pi-logo.png'
import ArduinoLogo from '../images/about-icons/arduino-logo.png'
import FirebaseLogo from '../images/about-icons/firebase.png'
import VueLogo from '../images/about-icons/Vue.js_Logo.svg'
import GatsbyLogo from '../images/about-icons/gatsby.svg'
import MongoDBLogo from '../images/about-icons/mongodb-logo.jpg'
import ExpressLogo from '../images/about-icons/express.png'
import HTMLCSSIcon from '../images/about-icons/html-css-logos.png'
import GraphQLLogo from '../images/about-icons/graph-ql-logo.svg'
import PythonLogo from '../images/about-icons/python-logo.svg'
import WebpackLogo from '../images/about-icons/webpack-logo.svg'
import UnityLogo from '../images/about-icons/unity-logo.svg'

const AboutPage = () => (
  <Layout>
    <div className="about-page">
      <h1><span role="img" aria-label="peace fingers">✌️</span> Hey, I'm Josh.</h1>
      <p>I am a freelance programmer who enjoys making creative custom solutions for the web.</p>
      <div className="row column-reverse-sm">
        <div className="col-xs-12 col-sm-12 col-md-9">

          <a href="https://www.trilogyed.com/" target="_blank" rel="noopener noreferrer" title="Trilogy Education Services">
            <img src={TrilogyIcon} alt="Trilogy Education Services Logo" className="white-bkgd" />
          </a>

          <p>Full-Stack Web Development Bootcamp Instructor at <a href="https://www.trilogyed.com/" target="_blank" rel="noopener noreferrer" title="Trilogy Education Services">Trilogy Education Services</a>. You can <a href="https://www.trilogyed.com/blog/as-a-freelance-web-developer-josh-naylor-runs-on-creativity-heres-how-he-brought-it-to-boot-camp-instructing" target="_blank" rel="noopener noreferrer">Read more about my experience teaching</a> on the Trilogy Blog.</p>
          
          <br/>
          <br/>
          
          <h3><span role="img" aria-label="food emojis">🍔🍉🥓</span> Hungry for more?</h3>
          
          <ul style={{ marginBottom: '26px' }}>
            <li><a href="https://github.com/8ctopotamus/" target="_blank" rel="noopener noreferrer" title="8ctopotamus Github Profile" style={{ fontWeight: 'bold' }}>Check out my github</a> to see some code.</li>
            <li><a href="https://www.linkedin.com/in/zylocodes/" target="_blank" rel="noopener noreferrer" title="LinkedIn Profile" style={{ fontWeight: 'bold' }}>LinkedIn</a></li>
          </ul>

          <Link
            to="/projects/"
            className="btn btn-blue"
            style={{marginRight: 14, marginBottom: 14}}>
              See projects
          </Link>

          <span style={{marginRight: 14, marginBottom: 14}}>- or -</span>

          <Link
            to="/contact/"
            className="btn btn-green">
              Contact
          </Link>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-3">
          <div className="josh-melodica-cell">
            <div className="josh-melodica-wrap"></div>
            <blockquote>"Yes, I make websites."</blockquote>
          </div>
        </div> 
      </div>

      <hr style={{marginTop: 50}} />

      <h3 style={{marginTop: 50}}>I make:</h3>
      <ul className="services-list">
        <li>(Progressive) web apps</li>
        <li>WordPress themes</li>
        <li>WordPress plugins</li>
        <li>Static sites</li>
        <li>Mobile apps (flutter/react native)</li>
        <li>2D/3D games</li>
        <li>Graphics</li>
        <li>Research &amp; Development</li>
        <li>Teaching &amp; Tutoring</li>
        <li>Training &mp; Consulting</li>
      </ul>

      <hr style={{marginTop: 50}} />

      <h3 style={{marginTop: 50}}>I use:</h3>
      <div id="tech" className="row about-icons">
        <a href="https://vuejs.org/" className="col-xs-4 col-sm-3" target="_blank" rel="noopener noreferrer" title="Vue">
          <img src={VueLogo} alt="Vue logo" />
          <span>Vue</span>
        </a>
        <a href="https://facebook.github.io/react/" className="col-xs-4 col-sm-3" target="_blank" rel="noopener noreferrer" title="React">
          <img src={ReactIcon} alt="React logo" />
          <span>React</span>
        </a>
        <a href="https://wordpress.org/" className="col-xs-4 col-sm-3" target="_blank" rel="noopener noreferrer" title="WordPress">
          <img className="white-bkgd" src={WPIcon} alt="WordPress logo" />
          <span>WordPress</span>
        </a>
        <a href="https://nodejs.org/en/" className="col-xs-4 col-sm-3" target="_blank" rel="noopener noreferrer" title="Javascript">
          <img className="white-bkgd" src={NodeIcon} alt="NodeJS logo" />
          <span>NodeJS</span>
        </a>
        <a href="https://unity3d.com/" className="col-xs-4 col-sm-3" target="_blank" rel="noopener noreferrer" title="Unity">
          <img className="white-bkgd" src={UnityLogo} alt="Unity logo" />
          <span>Unity 3D</span>
        </a>
        <a href="http://graphql.org/" className="col-xs-4 col-sm-3" target="_blank" rel="noopener noreferrer" title="GraphQL">
          <img className="white-bkgd" src={GraphQLLogo} alt="GraphQL logo" />
          <span>GraphQL</span>
        </a>
        <a href="https://flutter.dev/" className="col-xs-4 col-sm-3" target="_blank" rel="noopener noreferrer" title="React Native">
          <img className="white-bkgd" src={FlutterIcon} alt="Flutter logo" />
          <span>Flutter</span>
        </a>
        <a href="https://facebook.github.io/react-native/" className="col-xs-4 col-sm-3" target="_blank" rel="noopener noreferrer" title="React Native">
          <img src={ReactNativeIcon} alt="React Native logo" />
          <span>React Native</span>
        </a>
        <a href="https://www.polymer-project.org/" className="col-xs-4 col-sm-3" target="_blank" rel="noopener noreferrer" title="Polymer">
          <img src={PolymerIcon} alt="Polymer logo" />
          <span>Polymer</span>
        </a>
        <a href="https://phaser.io/" className="col-xs-4 col-sm-3" target="_blank" rel="noopener noreferrer" title="Phaser">
          <img className="white-bkgd" src={PhaserIcon} alt="Phaser logo" />
          <span>Phaser</span>
        </a>
        <a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript" className="col-xs-4 col-sm-3" target="_blank" rel="noopener noreferrer" title="Javascript">
          <img src={JavascriptIcon} alt="Javascript logo" />
          <span>JavaScript</span>
        </a>
        <a href="https://www.w3schools.com/" className="col-xs-4 col-sm-3" target="_blank" rel="noopener noreferrer" title="HTML/CSS">
          <img src={HTMLCSSIcon} alt="HTML/CSS logo" />
          <span>HTML/CSS</span>
        </a>
        <a href="https://www.gatsbyjs.org/" className="col-xs-4 col-sm-3" target="_blank" rel="noopener noreferrer" title="Gatsby">
          <img className="white-bkgd" src={GatsbyLogo} alt="Gatsby logo" />
          <span>Gatsby</span>
        </a>
        <a href="https://jekyllrb.com/" className="col-xs-4 col-sm-3" target="_blank" rel="noopener noreferrer" title="Jekyll">
          <img src={Jekylllogo} alt="Jekyll logo" />
          <span>Jekyll</span>
        </a>
        <a href="https://secure.php.net/" className="col-xs-4 col-sm-3" target="_blank" rel="noopener noreferrer" title="PHP">
          <img src={PHPlogo} alt="PHP logo" />
          <span>PHP</span>
        </a>
        <a href="https://www.python.org/" className="col-xs-4 col-sm-3" target="_blank" rel="noopener noreferrer" title="python">
          <img src={PythonLogo} alt="python logo" />
          <span>Python</span>
        </a>
        <a href="https://unity3d.com/" className="col-xs-4 col-sm-3" target="_blank" rel="noopener noreferrer" title="Unity">
          <img className="white-bkgd" src={Unitylogo} alt="Unity logo" />
          <span>Unity</span>
        </a>
        <a href="https://firebase.google.com/" className="col-xs-4 col-sm-3" target="_blank" rel="noopener noreferrer" title="Firebase">
          <img className="white-bkgd" src={FirebaseLogo} alt="Firebase logo" />
          <span>Firebase</span>
        </a>
        <a href="https://www.mongodb.com/" className="col-xs-4 col-sm-3" target="_blank" rel="noopener noreferrer" title="Express">
          <img className="white-bkgd" src={ExpressLogo} alt="Express logo" />
          <span>Express</span>
        </a>
        <a href="https://webpack.js.org/" className="col-xs-4 col-sm-3" target="_blank" rel="noopener noreferrer" title="Webpack">
          <img src={WebpackLogo} alt="Webpack logo" />
          <span>Webpack</span>
        </a>
        <a href="https://www.mongodb.com/" className="col-xs-4 col-sm-3" target="_blank" rel="noopener noreferrer" title="MongoDB">
          <img className="white-bkgd" src={MongoDBLogo} alt="Mongo DB logo" />
          <span>MongoDB</span>
        </a>
        <a href="https://www.adobe.com/products/illustrator.html" className="col-xs-4 col-sm-3" target="_blank" rel="noopener noreferrer" title="Adobe Illustrator">
          <img src={AdobeIllustratorlogo} alt="Adobe Illustrator logo" />
          <span>Illustrator</span>
        </a>
        <a href="https://www.adobe.com/products/aftereffects.html" className="col-xs-4 col-sm-3" target="_blank" rel="noopener noreferrer" title="Adobe After Effects">
          <img src={AdobeAfterEffectslogo} alt="Adobe After Effects logo" />
          <span>After Effects</span>
        </a>
        <a href="https://www.adobe.com/products/photoshop.html" className="col-xs-4 col-sm-3" target="_blank" rel="noopener noreferrer" title="Adobe Photoshop">
          <img src={AdobePhotoshoplogo} alt="Adobe Photoshop logo" />
          <span>Photoshop</span>
        </a>
        <a href="https://www.adobe.com/products/indesign.html" className="col-xs-4 col-sm-3" target="_blank" rel="noopener noreferrer" title="Adobe InDesign">
          <img src={AdobeInDesignlogo} alt="Adobe InDesign logo" />
          <span>InDesign</span>
        </a>
        <a href="https://www.adobe.com/products/premiere.html" className="col-xs-4 col-sm-3" target="_blank" rel="noopener noreferrer" title="Adobe Premiere Pro">
          <img src={AdobePremierePrologo} alt="Adobe Premiere Pro logo" />
          <span>Premiere Pro</span>
        </a>
        <a href="https://affinity.serif.com/en-us/designer/" className="col-xs-4 col-sm-3" target="_blank" rel="noopener noreferrer" title="Affinity Designer">
          <img src={AffinityDesignerlogo} alt="Affinity Designer" />
          <span>Affinity Designer</span>
        </a>
        <a href="https://affinity.serif.com/en-us/photo/" className="col-xs-4 col-sm-3" target="_blank" rel="noopener noreferrer" title="Affinity">
          <img src={AffinityPhotologo} alt="Affinity logo" />
          <span>Affinity Photo</span>
        </a>
        <a href="https://www.raspberrypi.org/" className="col-xs-4 col-sm-3" target="_blank" rel="noopener noreferrer" title="Raspberry Pi">
          <img src={RaspberryPilogo} alt="Raspberry Pi logo" />
          <span>Raspberry Pi</span>
        </a>
        <a href="https://www.arduino.cc/" className="col-xs-4 col-sm-3" target="_blank" rel="noopener noreferrer" title="Arduino">
          <img src={ArduinoLogo} alt="Arduino logo" />
          <span>Arduino</span>
        </a>
      </div>
    </div>
  </Layout>
)

export default AboutPage
